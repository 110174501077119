export enum SubscriptionInvoiceStatusEnum {
  WAITING_PAYMENT = 0,
  PAID = 1,
  CANCELED = 2,
  REFUSED = 3,
}

export const SubscriptionInvoiceStatusLabels = (
  subscriptionInvoiceStatus: SubscriptionInvoiceStatusEnum
): string => {
  const labels = {
    [SubscriptionInvoiceStatusEnum.WAITING_PAYMENT]: "Aguardando pagamento",
    [SubscriptionInvoiceStatusEnum.PAID]: "Pago",
    [SubscriptionInvoiceStatusEnum.CANCELED]: "Cancelado",
    [SubscriptionInvoiceStatusEnum.REFUSED]: "Recusado",
  };

  return labels[subscriptionInvoiceStatus];
};
