import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import {
  GetSubscriptionInvoiceProps,
  GetSubscriptionInvoiceResponse,
  SubscriptionPlanService,
} from "services/subscription-plan-service";
import { errorHandler } from "helpers/http-helper";

export const actionGetSubscriptionInvoices = action(
  async (
    input: GetSubscriptionInvoiceProps
  ): Promise<GetSubscriptionInvoiceResponse> => {
    SetIsLoadingState(true);

    try {
      return await SubscriptionPlanService.getSubscriptionInvoices(input);
    } catch (error) {
      errorHandler(error, "Falha ao buscar assinatura do plano");
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
