import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { SubscriptionPlanService, SubscriptionPlanSubscribeProps } from "services/subscription-plan-service";

export const actionCreateSubscription = action(
  async (input: SubscriptionPlanSubscribeProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await SubscriptionPlanService.subscribeSubscription(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
