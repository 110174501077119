import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { actionListAllHealthPlan } from "actions/health-plans/action-listall-health-plans";
import { ListAllHealthPlansResponse } from "services/health-plan-service";
import { ListHealthPlanTable } from "./health-plan-table";
import { getTableType } from "helpers/screen";
import { HealthPlanStatusEnum } from "domain/health-plans/entities/enums/health-plan-status.enum";
import { FormHealthPlan, HealthPlanProps } from "./form/form-health-plan";
import { errorMessage, successMessage } from "helpers/toast";
import { actionUpdateHealthPlan } from "actions/health-plans/action-update-health-plan";
import { actionCreateHealthPlan } from "actions/health-plans/action-create-health-plan";
import { BadgeComponent } from "components/badge/badge-component";

export interface ListHealthPlanItemTable {
  id: number;
  code: any;
  name: any;
  status: any;
}

export const HealthPlanContainer: React.FC = () => {
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());
  const [rawHealthPlans, setRawHealthPlans] = useState<
    ListAllHealthPlansResponse[]
  >([]);
  const [healthPlans, setHealthPlans] = useState<ListHealthPlanItemTable[]>([]);
  const [selectedHealthPlan, setSelectedHealthPlan] =
    useState<HealthPlanProps | null>(null);

  const fetchData = async () => {
    try {
      const response = await actionListAllHealthPlan({});
      setRawHealthPlans(response);

      const result = response.map((item) => ({
        id: item.id,
        code: codeItemName(item.code ?? "", String(item.id)),
        name: nameItemName(item.name, String(item.id)),
        status: statusItemName(item.status, String(item.id)),
      }));
      setHealthPlans(result);
    } catch (error) {}
  };

  const onSelectData = (id: string) => {
    const healthPlan = rawHealthPlans.find(
      (item) => String(item.id) === String(id)
    );
    if (healthPlan) {
      setSelectedHealthPlan(healthPlan);
    } else {
      console.log("Plano de Saúde não encontrado", id);
      errorMessage("Plano de Saúde não encontrado");
    }
  };

  const codeItemName = (name: string, id: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const nameItemName = (name: string, id: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const statusItemName = (
    healthPlanStatus: HealthPlanStatusEnum,
    id: string
  ) => {
    return (
      <div className="w-100">
        {healthPlanStatus == HealthPlanStatusEnum.ACTIVE ? (
          <>
            <BadgeComponent
              data={{ name: "Ativo", bgColor: "badge-bg-success" }}
            />
          </>
        ) : (
          <>
            <BadgeComponent
              data={{ name: "Inativo", bgColor: "badge-bg-danger" }}
            />
          </>
        )}
      </div>
    );
  };

  const onSubmit = async (values: HealthPlanProps) => {
    try {
      if (values.id) {
        await actionUpdateHealthPlan(values.id, values);
        successMessage("Plano de Saúde atualizado com sucesso");
      } else {
        await actionCreateHealthPlan(values);
        successMessage("Plano de Saúde criado com sucesso");
      }
      setSelectedHealthPlan(null);
      await fetchData();
    } catch {}
  };

  const onCreate = () => {
    setSelectedHealthPlan({
      code: "",
      name: "",
      status: HealthPlanStatusEnum.ACTIVE,
    });
  };
  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <div className="main-wrapper">
      <Row className="d-flex justify-content-end mt-2 mb-3">
        <div className="d-flex col-12 col-xl-3 my-2 justify-content-end">
          <Button color="primary" onClick={onCreate}>
            + Criar Plano de Saúde
          </Button>
        </div>
      </Row>

      <div>
        <ListHealthPlanTable
          data={healthPlans}
          onSelectData={onSelectData}
          tableType={tableType}
          setTableType={setTableType}
        />
      </div>

      <Modal
        isOpen={selectedHealthPlan !== null}
        toggle={() => setSelectedHealthPlan(null)}
        size="md"
      >
        <ModalHeader toggle={() => setSelectedHealthPlan(null)}>
          Plano de Saúde
        </ModalHeader>
        <ModalBody>
          <FormHealthPlan
            data={selectedHealthPlan}
            onSubmit={onSubmit}
            onCancel={() => setSelectedHealthPlan(null)}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};
