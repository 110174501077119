import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { SubscriptionPlanService } from "services/subscription-plan-service";

export const actionCancelSubscription = action(async (): Promise<void> => {
  SetIsLoadingState(true);
  try {
    await SubscriptionPlanService.cancelSubscription();
  } catch (error) {
    throw error;
  } finally {
    SetIsLoadingState(false);
  }
});
