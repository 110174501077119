import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import {
  listAllCardsResponse,
  SubscriptionPlanService,
} from "services/subscription-plan-service";

export const actionListAllCards = action(
  async (): Promise<listAllCardsResponse[]> => {
    SetIsLoadingState(true);

    try {
      return await SubscriptionPlanService.listAllCards();
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
