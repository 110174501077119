import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export const NoSubscriptionCard = () => {
  const navigate = useNavigate();

  return (
    <div className="subscription-card">
      <div className="d-flex flex-wrap justify-content-center align-items-center mb-2">
        <i className="fa fa-gift text-primary fs-19" aria-hidden="true"></i>{" "}
        &nbsp; &nbsp;
        <div className="font-weight-600 fs-19">Assinatura G3MED</div>
      </div>

      <div className="text-center">
        <p className="text-black-300 fs-16 font-weight-400">
          Você não possui nenhum plano ativo. Desbloqueie o acesso total aos
          recursos de gerenciamento de clínicas.
        </p>
        <Button
          color="primary"
          onClick={() => navigate("/painel/assinatura/assinar-plano")}
        >
          Ver planos disponíveis
        </Button>
      </div>
    </div>
  );
};
