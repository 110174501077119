import React, { ReactElement, useEffect, useState } from "react";
import { actionCreateSubscription } from "actions/subscription-plan/action-create-subscription";
import { SubscriptionPlanSubscribeProps } from "services/subscription-plan-service";
import { successMessage } from "helpers/toast";
import { errorHandler } from "helpers/http-helper";
import { getCardBrand } from "helpers/cards";
import { PaymentTypeEnum } from "domain/schedule/enums/payment-type.enum";
import { useNavigate } from "react-router-dom";
import { FormPersonalInfoSection } from "./form-sections/form-personal-info-section";
import { FormPaymentSection } from "./form-sections/form-payment-section";
import { FormAddressSection } from "./form-sections/form-address-section";
import subscriptionStore from "state/subscription/subscription-store";
import { Plan } from "./form-sections/form-plan-selector-section";
import { PaymentReview } from "./form-sections/form-payment-review-section";
import { actionRefreshToken } from "actions/identity/action-refresh-token";

export interface CardDetails {
  cvv: string;
  holder_document: string;
  number: string;
  holder_name: string;
  brand: string;
  expiration_month: string;
  expiration_year: string;
}

export interface Address {
  state: string;
  city: string;
  zip_code: string;
  complement: string;
  neighborhood: string;
  street: string;
  number: string;
}

export interface SubscriptionPayload {
  subscription_plan_id: number;
  payment_method: string;
  card: CardDetails;
  phone: string;
  name: string;
  document_number: string;
  address: Address;
}

interface SubscriptionFormProps {
  selectedPlan: Plan;
}

enum SubscriptionNavigation {
  PERSONAL_DATA = "PERSONAL_DATA",
  PAYMENT_DATA = "PAYMENT_DATA",
  ADDRESS_DATA = "ADDRESS_DATA",
  PAYMENT_REVIEW = "PAYMENT_REVIEW",
}

export const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
  selectedPlan,
}) => {
  const navigate = useNavigate();

  const handleSubmit = async (coupon?: string) => {
    try {
      const payload: SubscriptionPlanSubscribeProps = {
        ...formValues,
        subscription_plan_id: selectedPlan?.id,
        payment_method: PaymentTypeEnum.CREDIT_CARD,
        address: {
          ...formValues.address,
        },
        card: {
          ...formValues.card,
          expiration_month: formValues.card.expiration.split("/")[0],
          expiration_year: formValues.card.expiration.split("/")[1],
          brand: getCardBrand(formValues.card.number),
        },
        card_id: undefined,
        coupon: coupon,
      };

      await actionCreateSubscription(payload);
      await actionRefreshToken();
      navigate("/painel/assinatura/gerenciamento");
      successMessage("Assinatura realizada com sucesso");
    } catch (error) {
      errorHandler(error, "Falha ao realizar assinatura");
    }
  };

  const [activeTab, setActiveTab] = useState<SubscriptionNavigation>(
    SubscriptionNavigation.PERSONAL_DATA
  );

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    document_number: "",
    card: {
      holder_name: "",
      number: "",
      expiration: "",
      cvv: "",
      holder_document: "",
      brand: "",
    },
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      zip_code: "",
    },
  });

  useEffect(() => {
    if (activeTab === SubscriptionNavigation.PERSONAL_DATA) {
      subscriptionStore.setIsShowFeatures(true);
    } else {
      subscriptionStore.setIsShowFeatures(false);
    }
  }, [activeTab]);

  const onBackTab = () => {
    switch (activeTab) {
      case SubscriptionNavigation.PAYMENT_REVIEW:
        setActiveTab(SubscriptionNavigation.ADDRESS_DATA);
        break;
      case SubscriptionNavigation.ADDRESS_DATA:
        setActiveTab(SubscriptionNavigation.PAYMENT_DATA);
        break;
      case SubscriptionNavigation.PAYMENT_DATA:
        setActiveTab(SubscriptionNavigation.PERSONAL_DATA);
        break;
      case SubscriptionNavigation.PERSONAL_DATA:
        // navigate("/painel/assinatura/gerenciamento");
        break;
    }
  };

  const onSubmit = (values: any) => {
    setFormValues({ ...formValues, ...values });

    switch (activeTab) {
      case SubscriptionNavigation.PERSONAL_DATA:
        setActiveTab(SubscriptionNavigation.PAYMENT_DATA);
        break;
      case SubscriptionNavigation.PAYMENT_DATA:
        setActiveTab(SubscriptionNavigation.ADDRESS_DATA);
        break;
      case SubscriptionNavigation.ADDRESS_DATA:
        setActiveTab(SubscriptionNavigation.PAYMENT_REVIEW);
        break;
    }
  };

  const onFinish = async (coupon?: string) => {
    await handleSubmit(coupon);
  };

  const mapTabToComponent = new Map<SubscriptionNavigation, ReactElement>();
  mapTabToComponent.set(
    SubscriptionNavigation.PERSONAL_DATA,
    <FormPersonalInfoSection
      // onBack={onBackTab}
      onSubmit={onSubmit}
      data={{
        name: formValues.name,
        phone: formValues.phone,
        document_number: formValues.document_number,
      }}
    />
  );

  mapTabToComponent.set(
    SubscriptionNavigation.PAYMENT_DATA,
    <FormPaymentSection
      onBack={onBackTab}
      onSubmit={onSubmit}
      data={{ ...formValues.card }}
    />
  );

  mapTabToComponent.set(
    SubscriptionNavigation.ADDRESS_DATA,
    <FormAddressSection
      onBack={onBackTab}
      onSubmit={onSubmit}
      data={{ ...formValues.address }}
    />
  );

  mapTabToComponent.set(
    SubscriptionNavigation.PAYMENT_REVIEW,
    <PaymentReview
      onBack={onBackTab}
      onConfirm={onFinish}
      data={{
        plan: selectedPlan,
        name: formValues.name,
        document_number: formValues.document_number,
      }}
    />
  );

  return (
    <div className="d-flex col-12 row ">{mapTabToComponent.get(activeTab)}</div>
  );
};
