export enum SubscriptionPlanFrequencyEnum {
  Month = 'M',
  Year = 'Y',
}

export const SubscriptionPlanFrequencyLabels = (subscriptionPlanFrequency: SubscriptionPlanFrequencyEnum) => {
  const labels = {
    [SubscriptionPlanFrequencyEnum.Month]: 'Mensal',
    [SubscriptionPlanFrequencyEnum.Year]: 'Anual',
  };

  return labels[subscriptionPlanFrequency];
};
