import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { CouponService, ValidateCouponResponse } from "services/coupon-service";
import { errorMessage } from "helpers/toast";

export const actionValidateCoupon = action(
  async (code: string): Promise<ValidateCouponResponse> => {
    if (!code) {
      errorMessage("Cupom inválido");
      throw new Error("Cupom inválido");
    }

    SetIsLoadingState(true);
    try {
      return await CouponService.validateCoupon(code);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
