import React from "react";
import { Field, Form, Formik } from "formik";
import { Button, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";

import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { validateCNPJ, validateCPF } from "validators/validate-document";

const subscriptionSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Digite nome completo")
    .matches(/\s/, "Digite nome completo")
    .required("Digite nome completo"),

  phone: Yup.string()
    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, "Telefone inválido")
    .required("Telefone inválido"),

  document_number: Yup.string()
    .test("custom-validation", "CPF/CNPJ inválido", function (value) {
      return (
        (value?.length && validateCNPJ(value ?? "")) || validateCPF(value ?? "")
      );
    })
    .required("CPF/CNPJ inválido"),
});

interface FormPersonalInfoSectionProps {
  onSubmit: (values: any) => void;
  onBack?: () => void;
  data?: {
    name: string;
    phone: string;
    document_number: string;
  };
}
export const FormPersonalInfoSection: React.FC<
  FormPersonalInfoSectionProps
> = ({ onSubmit, onBack, data }: FormPersonalInfoSectionProps) => {
  const initialValues = {
    name: data?.name || "",
    phone: data?.phone || "",
    document_number: data?.document_number || "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={subscriptionSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3 className="h4 mb-3">Informações Nota Fiscal</h3>
            <div className="col-12 d-flex row mx-0">
              <FormGroup className="col-12 ">
                <Label htmlFor="name">
                  Nome Completo da clínica/responsável
                </Label>
                <Field
                  name="name"
                  id="name"
                  label="Nome"
                  placeholder="Nome Completo"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="document_number">
                  CNPJ/CPF da clínica/responsável
                </Label>
                <Field
                  name="document_number"
                  id="document_number"
                  label="CNPJ/CPF"
                  placeholder="CNPJ/CPF"
                  component={CustomFormInput}
                />
              </FormGroup>

              <FormGroup className="col-12 col-md-6">
                <Label htmlFor="phone">Telefone</Label>
                <Field
                  name="phone"
                  id="phone"
                  label="Data"
                  mask="(00) 00000-0000"
                  placeholder="(00) 00000-0000"
                  component={CustomMaskInput}
                />
              </FormGroup>
            </div>
            <div className="col-12 d-flex justify-content-end px-2">
              {onBack && (
                <Button className="mx-2" onClick={onBack}>
                  Voltar
                </Button>
              )}
              <Button type="submit" disabled={isSubmitting} color="primary">
                {isSubmitting ? "Processing..." : "Continuar"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
