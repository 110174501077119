import { Button } from "reactstrap";
import { Plan } from "./form-plan-selector-section";

interface FormChangeCardReviewSectionProps {
  onConfirm: () => void;
  onBack?: () => void;
}

export function FormChangeCardReviewSection({
  onConfirm,
  onBack,
}: FormChangeCardReviewSectionProps) {
  return (
    <div className="col-12 d-flex w-100 justify-content-center ">
      <div className="rounded-075 bg-white p-4 text-center border-shadown">
        <h3 className="h4 mb-3">
          Deseja realmente alterar o cartão de pagamento ?
        </h3>

        {/* <div className="text-center fs-14 text-black-300 font-weight-400">
          <i className="fa fa-lock text-primary" aria-hidden="true"></i>{" "}
          <span>Your payment information is secure and encrypted</span>
        </div> */}

        <div className="col-12 d-flex justify-content-end px-2 flex-wrap">
          <Button onClick={onConfirm} className="col-12" color="primary">
            Finalizar
          </Button>

          {onBack && (
            <Button className="col-12 mt-2" onClick={onBack}>
              Voltar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
