export enum SubscriptionStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0,
  EXPIRED = 2,
  TRIAL = 3,
  CANCELED = 4,
  PAYMENT_FAILED = 5,
}

export const SubscriptionStatusLabels = (status: SubscriptionStatusEnum) => {
  const labels = {
    [SubscriptionStatusEnum.ACTIVE]: "Ativo",
    [SubscriptionStatusEnum.INACTIVE]: "Inativo",
    [SubscriptionStatusEnum.EXPIRED]: "Expirado",
    [SubscriptionStatusEnum.TRIAL]: "Trial",
    [SubscriptionStatusEnum.CANCELED]: "Cancelado",
    [SubscriptionStatusEnum.PAYMENT_FAILED]: "Pagamento falhou",
  };

  return labels[status];
};
