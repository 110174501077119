import {
  SubscriptionInvoiceStatusEnum,
  SubscriptionInvoiceStatusLabels,
} from "domain/subscription-plans/enums/subscription-invoice-status.enum";
import { CustomTable, PaginationMeta } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";
import { dateFormatter } from "helpers/date-formatter";
import { labelMaskFormatToReal } from "helpers/format-money";
import { BadgeComponent } from "components/badge/badge-component";

export interface Payment {
  id: number;
  name: string;
  date: string;
  amount: string;
  status: SubscriptionInvoiceStatusEnum;
}
export interface PaymentHistoryTableProps {
  payments: Payment[] | null;
  meta: PaginationMeta | null;
  onSelectItem: (id: string) => void;
  onChangePage: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}

export const PaymentHistoryTable = ({
  payments,
  meta,
  onSelectItem,
  onChangePage,
  tableType,
  setTableType,
}: PaymentHistoryTableProps) => {
  const getStatusColor = (status: SubscriptionInvoiceStatusEnum) => {
    switch (status) {
      case SubscriptionInvoiceStatusEnum.WAITING_PAYMENT:
        return "badge-bg-warning";
      case SubscriptionInvoiceStatusEnum.PAID:
        return "badge-bg-primary";
      case SubscriptionInvoiceStatusEnum.CANCELED:
        return "badge-bg-danger";
      case SubscriptionInvoiceStatusEnum.REFUSED:
        return "badge-bg-danger";
    }
  };

  const columnNames = {
    name: "Nome",
    date: "Data",
    status: "Status",
    valor: "Valor",
  };

  const paymentItemAmount = (amount: string, id: string) => {
    return (
      <div className="w-100" onClick={() => onSelectItem(id)}>
        <span>{labelMaskFormatToReal(amount)}</span>
      </div>
    );
  };

  const paymentItemDate = (date: string | null, id: string) => {
    return (
      <div className="w-100" onClick={() => onSelectItem(id)}>
        <span>{dateFormatter(date, "dd/MM/yyyy")}</span>
      </div>
    );
  };

  const paymentItemStatus = (
    status: SubscriptionInvoiceStatusEnum,
    id: string
  ) => {
    return (
      <div className="w-100" onClick={() => onSelectItem(id)}>
        <BadgeComponent
          data={{
            name: SubscriptionInvoiceStatusLabels(status),
            bgColor: getStatusColor(status),
          }}
        />
      </div>
    );
  };

  const paymentHistory =
    payments?.map((payment) => ({
      id: payment.id,
      // name: paymentItemName(payment.name, String(payment.id)),
      name: payment.name,
      date: paymentItemDate(payment.date, String(payment.id)),
      valor: paymentItemAmount(payment.amount, String(payment.id)),
      status: paymentItemStatus(payment.status, String(payment.id)),
    })) ?? null;
  return (
    <>
      <CustomTable
        columnNames={columnNames}
        data={paymentHistory}
        onSelect={onSelectItem}
        tableType={tableType}
        setTableType={setTableType}
        btnLabelNotFound="Nenhum histórico encontrado"
        btnClickNotFound={() => {}}
      />
      <CustomPagination
        {...Object.assign({}, meta)}
        onChangePage={onChangePage}
      />{" "}
    </>
  );
};
