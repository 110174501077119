interface BadgeComponentProps {
  data: {
    name: string;
    bgColor: string;
  };
}
export const BadgeComponent: React.FC<BadgeComponentProps> = ({
  data,
}: BadgeComponentProps) => {
  const { name, bgColor } = data;
  return (
    <>
      <span className={`badge py-2 fs-10 ${bgColor}`}>{name}</span>
    </>
  );
};
