import { Button, FormGroup, Label, Row } from "reactstrap";
import { validateCPF } from "validators/validate-document";
import "react-datepicker/dist/react-datepicker.css";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import {
  ProfessionTypeEnum,
  ProfessionTypeEnumLabels,
} from "domain/user/entities/enums/profession-type.enum";
import {
  UserStatusEnum,
  UserStatusEnumLabels,
} from "domain/user/entities/enums/user-status.enum";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { SelectUfForm } from "components/form/input/select-uf-form";
import { SelectProfessionTypeForm } from "components/form/input/select-profession-type-form";
import { SelectUserStatusForm } from "components/form/input/select-user-status-form";
import { getCityByUf } from "helpers/cities-list";
import { isPhoneValid } from "helpers/phone-validator";

export interface FormDoctorDataProps {
  doctorData: DoctorDataProps;
  onSubmit: (doctor: DoctorDataProps) => void;
  onCancel: () => void;
  isReadOnly?: boolean;
}

export interface DoctorDataProps {
  id?: number;
  email: string;
  name: string;
  phone: string;
  document_number: string | null;
  created_at?: string;
  updated_at?: string;
  status: UserStatusEnum;
  profession: ProfessionTypeEnum;
  register_number: [
    {
      id?: number;
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
}
export interface DoctorDataFormValues {
  id?: number;
  email: string;
  name: string;
  phone: string;
  document_number: string | null;
  created_at?: string;
  updated_at?: string;
  status: {
    value: UserStatusEnum;
    label: string;
  };
  profession: {
    value: ProfessionTypeEnum;
    label: string;
  };
  register_number: string;
  register_number_uf: {
    value: string;
    label: string;
  };
}

export const FormDoctorData: React.FC<FormDoctorDataProps> = ({
  doctorData,
  onSubmit,
  onCancel,
  isReadOnly,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    phone: Yup.string().test("phone", "Telefone inválido", (value) => {
      return isPhoneValid(value ?? "");
    }),
    email: Yup.string().email("E-mail inválido").required("E-mail obrigatório"),
    document_number: Yup.string().test(
      "custom-validation",
      "CPF inválido",
      function (value) {
        // if (!value || value.length === 0) return true;
        return validateCPF(value ?? "");
      }
    ),
    register_number: Yup.string()
      .required("Número de registro é obrigatório")
      .min(3, "Número de registro inválido"),
    register_number_uf: Yup.object().test(
      "custom-validation-uf",
      "UF inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    status: Yup.object().test(
      "custom-validation-status",
      "Status inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    profession: Yup.object().test(
      "custom-validation-profession",
      "Conselho inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
  });

  const initialValues: DoctorDataFormValues = {
    id: doctorData.id,
    email: doctorData.email,
    name: doctorData.name,
    document_number: doctorData.document_number,
    phone: doctorData.phone,
    created_at: doctorData.created_at,
    updated_at: doctorData.updated_at,
    status: {
      value: doctorData.status,
      label: UserStatusEnumLabels(doctorData.status),
    },
    profession: {
      value: doctorData.profession,
      label: ProfessionTypeEnumLabels(doctorData.profession),
    },
    register_number: doctorData.register_number[0].register_number,
    register_number_uf: getCityByUf(
      doctorData.register_number[0].register_number_uf
    ) as any,
  };

  const handleSubmit = async (values: DoctorDataFormValues) => {
    return await onSubmit({
      ...values,
      document_number:
        values.document_number?.length == 0 ? null : values.document_number,
      status: values.status?.value ?? UserStatusEnum.ACTIVE,
      profession: values.profession?.value ?? ProfessionTypeEnum.DOCTOR,
      register_number: [
        {
          id: doctorData.register_number?.[0]?.id,
          register_number: values.register_number,
          register_number_uf: values.register_number_uf.value,
          is_selected: true,
        },
      ],
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6">
                <Label htmlFor="name">Nome completo*</Label>
                <Field
                  name="name"
                  id="name"
                  placeholder="Nome do médico"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label htmlFor="document_number">CPF do médico</Label>
                <Field
                  name="document_number"
                  id="document_number"
                  placeholder="CPF do médico"
                  type="text"
                  mask="000.000.000-00"
                  component={CustomMaskInput}
                  disabled={doctorData.id !== undefined || isReadOnly}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 col-xl-4">
                <Label htmlFor="email">E-mail*</Label>
                <Field
                  name="email"
                  id="email"
                  placeholder="Digite e-mail"
                  type="email"
                  disabled={isReadOnly}
                  component={CustomFormInput}
                />
              </FormGroup>

              <FormGroup className="col-12 col-md-6 col-xl-4">
                <Label htmlFor="email">Telefone*</Label>
                <Field
                  name="phone"
                  id="phone"
                  placeholder="(00) 00000-0000"
                  type="text"
                  mask="(00) 00000-0000"
                  component={CustomMaskInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 col-xl-4">
                <SelectUserStatusForm
                  keyName="status"
                  isReadOnly={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 col-xl-4">
                <SelectProfessionTypeForm
                  isReadOnly={doctorData.id !== undefined || isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 col-xl-4">
                <Label htmlFor="email">Número de registro</Label>
                <Field
                  name="register_number"
                  id="register_number"
                  placeholder="Número de registro"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 col-xl-4">
                <SelectUfForm
                  keyName="register_number_uf"
                  isReadOnly={isReadOnly}
                />
              </FormGroup>
            </Row>

            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <div className="col-12 col-md-3 ">
                <Button
                  color="secondary"
                  className="form-control"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </div>
              {!isReadOnly && (
                <div className="col-12 col-md-3 ">
                  <Button
                    type="submit"
                    color="primary"
                    className="form-control"
                  >
                    Salvar
                  </Button>
                </div>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
