import { useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { actionValidateCoupon } from "actions/coupons/action-validate-coupon";
import { ValidateCouponResponse } from "services/coupon-service";

interface CouponValidateProps {
  onSetCoupon: (coupon: string) => void;
  onSuccess: (values: ValidateCouponResponse) => void;
}

export const CouponValidate: React.FC<CouponValidateProps> = ({
  onSuccess,
  onSetCoupon,
}: CouponValidateProps) => {
  const [coupon, setCoupon] = useState<string>("");
  const onValidateCoupon = async () => {
    try {
      const response = await actionValidateCoupon(coupon);
      onSetCoupon(coupon);
      await onSuccess(response);
    } catch {}
  };

  return (
    <>
      <InputGroup>
        <Input
          type="text"
          value={coupon}
          placeholder={"Cupom de desconto"}
          onChange={(e) => setCoupon(e.target.value)}
          className="border-right-0"
        />

        <InputGroupText className="bg-white">
          <button className="border-0 bg-white" onClick={onValidateCoupon}>
            <i
              className="fa fa-search text-primary fs-20"
              aria-hidden="true"
            ></i>
          </button>
        </InputGroupText>
      </InputGroup>
    </>
  );
};
