import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { actionListAllSubscriptionPlans } from "actions/subscription-plan/action-listall-subscription-plans";
import { SubscriptionPlan } from "services/subscription-plan-service";
import { SubscriptionPlanFrequencyEnum } from "domain/subscription-plans/enums/subscription-plan-frequency.enum";
import { labelMaskFormatToReal } from "helpers/format-money";
import subscriptionStore from "state/subscription/subscription-store";

export interface Plan {
  id: number;
  title: string;
  subtitle: string;
  price: number;
  is_default: boolean;
  frequency: SubscriptionPlanFrequencyEnum;
  features: string[];
  installments: number;
}

interface FormPlanSelectorProps {
  selectedPlan?: Plan;
  onSelectPlan: (plan: Plan) => void;
}

export const FormPlanSelector: React.FC<FormPlanSelectorProps> = observer(
  ({ selectedPlan, onSelectPlan }) => {
    const [plans, setPlans] = useState<Plan[]>([]);

    const fetchAllPlans = async () => {
      try {
        const response = await actionListAllSubscriptionPlans();
        const data = response.map((plan: SubscriptionPlan) => ({
          id: plan.id,
          title: plan.name,
          subtitle: plan.subtitle ?? "",
          price: plan.price,
          frequency: plan.frequency,
          features: plan.description,
          installments: plan.installments,
          is_default: plan.is_default,
        }));

        onSelectPlan(data.find((plan) => plan.is_default === true) ?? data[0]);

        setPlans(data);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      (async () => {
        await fetchAllPlans();
      })();
    }, []);

    return (
      <div className="row mb-3">
        {plans.map((plan) => (
          <div key={plan.id} className="col-md-4">
            <div
              className={`card h-100 cursor-pointer rounded-075 border-0 border-shadown ${
                selectedPlan?.id === plan.id ? "border-primary bg-primary" : ""
              }`}
              onClick={() => onSelectPlan(plan)}
              style={{ cursor: "pointer" }}
            >
              <div
                className={`card-body ${
                  selectedPlan?.id !== plan.id ? "text-primary" : "text-white"
                }`}
              >
                <h4 className="font-weight-600">{plan.title}</h4>
                <p className="font-weight-400 fs-14">{plan.subtitle}</p>
                <p className="fs-30 font-weight-400 mb-4">
                  {labelMaskFormatToReal(
                    String(plan.price / plan.installments)
                  )}{" "}
                  <small className="fs-6"> mês</small>
                </p>
                {subscriptionStore.getIsShowFeatures() === true && (
                  <>
                    <ul className="list-unstyled">
                      {plan.features.map((feature, index) => (
                        <li key={index} className="mb-2">
                          <i
                            className={`fa fa-check-circle ${
                              selectedPlan?.id !== plan.id
                                ? "text-primary"
                                : "text-white"
                            }`}
                            aria-hidden="true"
                          ></i>
                          &nbsp; &nbsp;
                          <span
                            className={`font-weight-400 fs-14 ${
                              selectedPlan?.id !== plan.id
                                ? "text-black-500"
                                : "text-white"
                            }`}
                          >
                            {feature}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
);
