import { Button, FormGroup, Label } from "reactstrap";
import { Field, Formik, Form, useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";

import { CustomFormInput } from "components/form/input/custom-form-input";
import { actionFetchAddress } from "actions/address/action-fetch-address";
import { getCityByUf } from "helpers/cities-list";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { SelectUfForm } from "components/form/input/select-uf-form";

export const subscriptionSchema = Yup.object().shape({
  address: Yup.object().shape({
    zip_code: Yup.string()
      .matches(/^\d{8}$/, "CEP Inválido")
      .required("CEP Inválido"),

    street: Yup.string()
      .min(3, "Logradouro inválido")
      .required("Logradouro inválido"),

    number: Yup.string().optional(),

    complement: Yup.string(),

    neighborhood: Yup.string()
      .min(3, "Bairro inválido")
      .required("Bairro inválido"),

    city: Yup.string()
      .min(3, "Cidadade inválida")
      .required("Cidadade inválida"),

    state: Yup.object().test(
      "custom-validation-uf",
      "Estado inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
  }),
});

interface FormAddressSectionProps {
  onSubmit: (values: any) => void;
  onBack?: () => void;
  data?: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    zip_code: string;
  };
}

export const FormAddressSection: React.FC<FormAddressSectionProps> = ({
  onSubmit,
  onBack,
  data,
}: FormAddressSectionProps) => {
  const ZipCodeForm: React.FC = () => {
    const { setFieldValue } = useFormikContext();

    const handleZipCodeChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const zipCode = e.target.value.replace(/\D/g, "");

      setFieldValue("address.zip_code", zipCode);

      if (zipCode.length === 8) {
        try {
          const result = await actionFetchAddress(zipCode);
          const state = getCityByUf(result.state);

          setFieldValue("address.street", result.street);
          setFieldValue("address.neighborhood", result.neighborhood);
          setFieldValue("address.city", result.city);
          setFieldValue("address.state", state);
        } catch {}
      }
    };

    return (
      <Field
        id="address.zip_code"
        name="address.zip_code"
        type="text"
        placeholder="00000-000"
        mask="00000-000"
        onChange={handleZipCodeChange}
        component={CustomMaskInput}
      />
    );
  };

  const handleSubmit = async (values: any) => {
    await onSubmit({
      address: {
        ...values.address,
        state: values.address.state.value,
      }
    });
  };
  const initialValues = {
    address: {
      street: data?.street || "",
      number: data?.number || "",
      complement: data?.complement || "",
      neighborhood: data?.neighborhood || "",
      city: data?.city || "",
      state: data?.state || "",
      zip_code: data?.zip_code || "",
    },
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={subscriptionSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3 className="h4 mb-3">Informações de Endereço</h3>
            <div className="row col-12 mx-0">
              <FormGroup className="col-12 col-md-6 col-xl-3">
                <Label htmlFor="name">CEP</Label>
                <ZipCodeForm />
              </FormGroup>

              <FormGroup className="col-12 col-md-6 col-xl-3 ">
                <Label htmlFor="address.street">Logradouro</Label>
                <Field
                  name="address.street"
                  id="address.street"
                  label="Rua"
                  placeholder="Rua"
                  component={CustomFormInput}
                />
              </FormGroup>

              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="address.complement">Complemento</Label>
                <Field
                  name="address.complement"
                  id="address.complement"
                  label="Número"
                  placeholder="Complemento"
                  component={CustomFormInput}
                />
              </FormGroup>
            </div>

            <div className="col-12 row">
              <FormGroup className="col-12 col-md-6 col-xl-3">
                <Label htmlFor="address.number">Número</Label>
                <Field
                  name="address.number"
                  id="address.number"
                  label="Número"
                  placeholder="Número do endereço"
                  component={CustomFormInput}
                />
              </FormGroup>

              <FormGroup className="col-12 col-md-6 col-xl-3">
                <Label htmlFor="address.neighborhood">Bairro</Label>
                <Field
                  name="address.neighborhood"
                  id="address.neighborhood"
                  label="Bairro"
                  placeholder="Bairro"
                  component={CustomFormInput}
                />
              </FormGroup>

              <FormGroup className="col-12 col-md-6 col-xl-3">
                <SelectUfForm keyName="address.state" />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 col-xl-3">
                <Label htmlFor="address.city">Cidade</Label>
                <Field
                  name="address.city"
                  id="address.city"
                  placeholder="Cidade"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
            </div>

            <div className="col-12 d-flex justify-content-end px-2">
              {onBack && (
                <Button className="mx-2" onClick={onBack}>
                  Voltar
                </Button>
              )}
              <Button type="submit" disabled={isSubmitting} color="primary">
                {isSubmitting ? "Processing..." : "Continuar"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
