import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "@fortawesome/fontawesome-free/css/all.min.css";

import PainelLayout from "layouts/painel-layout/painel-layout";
import AuthLayout from "layouts/auth-layout/auth-layout";
import { getLocalAccessToken } from "config/http-client";

import reportWebVitals from "./reportWebVitals";
import "./index.css";
import config from "./config/config";
import ScrollToTop from "./components/utils/scroll-to-top";
import UserIdentity from "./domain/user/entities/user-identity";
import { errorMessage } from "./helpers/toast";

const siteKey = config()["RECAPTCHA_SITE_KEY"];

const SubscriptionGuard = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const data = UserIdentity.getData();

  React.useEffect(() => {
    if (
      data.subscription &&
      data.subscription.days_remaining !== null &&
      data.subscription.days_remaining < 0 &&
      location.pathname !== "/painel/assinatura/assinar-plano" &&
      location.pathname !== "/painel/assinatura/gerenciamento"
    ) {
      errorMessage("Sua assinatura de plano de uso expirou");
      navigate("/painel/assinatura/gerenciamento");
    }
  }, [data, navigate]);

  return <>{children}</>;
};

const RedirectToHome = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = getLocalAccessToken();
    if (!token) {
      navigate("/auth/login");
      return;
    }

    navigate("/painel/index");
  }, [navigate]);
  return null;
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        {/* <Route path="/painel/*" element={<PainelLayout />} /> */}
        <Route
          path="/painel/*"
          element={
            <SubscriptionGuard>
              <PainelLayout />
            </SubscriptionGuard>
          }
        />
        <Route path="/" element={<RedirectToHome />} />
      </Routes>
    </BrowserRouter>
  </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
