import { useEffect, useState } from "react";
import { SubscriptionForm } from "./subscription-form";
import {
  FormPlanSelector,
  Plan,
} from "./form-sections/form-plan-selector-section";
import subscriptionStore from "state/subscription/subscription-store";

export const SubscriptionContainer: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<Plan>();

  useEffect(() => {
    subscriptionStore.setCards([]);
  }, []);
  return (
    <div className="main-wrapper">
      <FormPlanSelector
        selectedPlan={selectedPlan}
        onSelectPlan={setSelectedPlan}
      />

      {selectedPlan && <SubscriptionForm selectedPlan={selectedPlan} />}
    </div>
  );
};
