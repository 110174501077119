import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";

import { Button, FormGroup, Label } from "reactstrap";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import subscriptionStore from "state/subscription/subscription-store";
import { getCardImage } from "helpers/cards";
import { validateLuhn } from "validators/validate-luhn";
import { validateCNPJ, validateCPF } from "validators/validate-document";

const baseSchema = {
  holder_name: Yup.string()
    .min(3, "Nome do titular inválido")
    .required("Nome do titular inválido"),
  number: Yup.string()
    .test("card", "Número do cartão inválido", (value) =>
      validateLuhn(value ?? "")
    )
    .required("Número do cartão inválido"),
  expiration: Yup.string()
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Data de expiração inválida")
    .required("Data de expiração inválida"),
  cvv: Yup.string()
    .matches(/^\d{3,4}$/, "CVV inválido")
    .required("CVV é obrigátorio"),
  holder_document: Yup.string()
    .test(
      "cpf-cnpj",
      "CPF/CNPJ inválido",
      (value) =>
        (value?.length && validateCNPJ(value ?? "")) || validateCPF(value ?? "")
    )
    .required("CPF/CNPJ do titular inválido"),
};

const subscriptionSchema = (isNewCard: boolean) =>
  Yup.object().shape({
    card: Yup.object().shape(
      isNewCard ? baseSchema : {} // Apply validation only if adding a new card
    ),
  });

interface FormPaymentSectionProps {
  onSubmit: (values: any) => void;
  onBack?: () => void;
  data?: {
    holder_name: string;
    number: string;
    expiration: string;
    cvv: string;
    holder_document: string;
    brand: string;
  };
}

export const ADD_NEW_CARD = "add_new_card";

export const FormPaymentSection: React.FC<FormPaymentSectionProps> = observer(
  ({ onSubmit, onBack, data }) => {
    const cards = subscriptionStore.getCards();
    const [selectedCard, setSelectedCard] = useState<string | null>(
      ADD_NEW_CARD
    );

    useEffect(() => {
      const cardId = cards?.find((card) => card.is_used == true)?.id ?? null;
      setSelectedCard(cardId ? String(cardId) : ADD_NEW_CARD);
    }, [subscriptionStore.getCards()]);

    const handleCardSelection = (cardId: string) => {
      setSelectedCard(cardId);
    };

    const initialValues = {
      card: {
        // card_id: String(selectedCard),
        holder_name: data?.holder_name || "",
        number: data?.number || "",
        expiration: data?.expiration || "",
        cvv: data?.cvv || "",
        holder_document: data?.holder_document || "",
        brand: data?.brand || "",
      },
    };

    const handleSubmit = (values: any) => {
      const data = {
        ...values,
        card_id: selectedCard,
      };
      onSubmit(data);
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={subscriptionSchema(selectedCard === "add_new_card")}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3 className="h4 mb-3">Informações de pagamento</h3>

            <div className="rounded-075 bg-white py-2 mb-4">
              {cards?.map((card, index) => (
                <div key={index}>
                  <FormGroup check key={index}>
                    <Label check>
                      <Field
                        type="radio"
                        name="card.card_id"
                        value={String(card.id)}
                        checked={selectedCard === String(card.id)}
                        onChange={() => handleCardSelection(String(card.id))}
                      />

                      <img
                        src={`/${getCardImage(card.brand)}`}
                        alt={card.brand}
                        className="mx-2"
                      />
                      <span>{`${card.brand} **** ${card.last_digits}`}</span>
                    </Label>
                  </FormGroup>
                  <hr />
                </div>
              ))}
              <div key={"add-card"} className="">
                <FormGroup check>
                  <Label check>
                    <Field
                      type="radio"
                      name="card.card_id"
                      value="add_new_card"
                      checked={selectedCard === "add_new_card"}
                      onChange={() => handleCardSelection("add_new_card")}
                    />

                    <img
                      src={`/${getCardImage("unknown")}`}
                      alt={"unknown"}
                      className="mx-2"
                    />
                    <span className="text-muted">
                      <i>Adicionar novo cartão</i>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            </div>

            {(selectedCard === "add_new_card" || cards.length === 0) && (
              <FormPaymentCardInfo />
            )}

            <div className="col-12 d-flex justify-content-end">
              {onBack && (
                <Button className="mx-2" onClick={onBack}>
                  Voltar
                </Button>
              )}
              <Button type="submit" color="primary">
                {isSubmitting ? "Processing..." : "Continuar"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
);

const FormPaymentCardInfo = () => {
  return (
    <>
      <div className="col-12 d-flex row">
        <FormGroup className="col-12 col-md-6 ">
          <Label htmlFor="card.number">Número do cartão</Label>
          <Field
            name="card.number"
            id="card.number"
            label="Número"
            placeholder="Número"
            component={CustomFormInput}
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-3">
          <Label htmlFor="card.expiration">Data de expiração</Label>
          <Field
            name="card.expiration"
            id="card.expiration"
            label="Data"
            mask="00/00"
            placeholder="MM/AA"
            component={CustomMaskInput}
          />
        </FormGroup>

        <FormGroup className="col-12 col-md-3">
          <Label htmlFor="card.number">CVV</Label>
          <Field
            name="card.cvv"
            id="card.cvv"
            label="cvv"
            mask="000"
            placeholder="XXX"
            component={CustomMaskInput}
          />
        </FormGroup>
      </div>

      <div className="col-12 d-flex row">
        <FormGroup className="col-12 col-md-6">
          <Label htmlFor="card.holder_name">Nome titular do cartão </Label>
          <Field
            name="card.holder_name"
            id="card.holder_name"
            label="Nome Completo"
            placeholder="Nome Completo"
            component={CustomFormInput}
          />
        </FormGroup>

        <FormGroup className="col-12 col-md-6">
          <Label htmlFor="card.holder_document">
            CPF/CNPJ titular do cartão{" "}
          </Label>
          <Field
            name="card.holder_document"
            id="card.holder_document"
            label="CPF/CNPJ"
            placeholder="CPF/CNPJ"
            component={CustomFormInput}
          />
        </FormGroup>
      </div>
    </>
  );
};
