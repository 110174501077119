import { CardEnum } from "domain/subscription-plans/enums/card.enum";

export const getCardBrand = (cardNumber: string) => {
  const brands = [
    { name: CardEnum.VISA, pattern: /^4[0-9]{12}(?:[0-9]{3})?$/ },
    {
      name: CardEnum.MASTERCARD,
      pattern: /^(?:5[1-5][0-9]{14}|2(?:2[2-9]|[3-7][0-9])[0-9]{12})$/,
    },
    { name: CardEnum.AMEX, pattern: /^3[47][0-9]{13}$/ },
    {
      name: CardEnum.DISCOVER,
      pattern:
        /^(?:6011|622(?:12[6-9]|1[3-9][0-9]|2[0-5][0-9])|64[4-9]|65)[0-9]{12}$/,
    },
    { name: CardEnum.DINERS, pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/ },
    { name: CardEnum.JCB, pattern: /^(?:2131|1800|35\d{3})\d{11}$/ },
    {
      name: CardEnum.ELO,
      pattern: /^(?:401178|401179|431274|451416|506699|5067|4576|636297|636368|650|651|655)/,
    },
  ];

  for (const brand of brands) {
    if (brand.pattern.test(cardNumber)) {
      return brand.name;
    }
  }
  return "Unknown";
};


export const getCardImage = (url: string) => {
  switch (url) {
    case CardEnum.VISA:
      return "brand_visa.svg";
    case CardEnum.MASTERCARD:
      return "brand_mastercard.svg";
    case CardEnum.ELO:
      return "brand_elo.svg";
    case CardEnum.DINERS:
      return "brand_diners.svg";
    case CardEnum.AMEX:
      return "brand_amex.svg";
    default:
      return "brand_credit_card.svg";
  }
};
