import { Button } from "reactstrap";
import { Plan } from "./form-plan-selector-section";
import { labelMaskFormatToReal } from "helpers/format-money";
import {
  SubscriptionPlanFrequencyEnum,
  SubscriptionPlanFrequencyLabels,
} from "domain/subscription-plans/enums/subscription-plan-frequency.enum";
import { useState } from "react";
import { CouponValidate } from "components/coupon/coupon-validate";
import { ValidateCouponResponse } from "services/coupon-service";
import { CouponDiscountType } from "domain/coupons/enums/coupon-discount-type.enum";

interface PaymentReviewProps {
  data: {
    plan: Plan;
    name: string;
    document_number: string;
  };
  onConfirm: (coupon?: string) => void;
  onBack?: () => void;
}

export function PaymentReview({ data, onConfirm, onBack }: PaymentReviewProps) {
  const [discount, setDiscount] = useState<ValidateCouponResponse>();
  const [coupon, setCoupon] = useState<string>();

  const onValidatedCoupon = async (values: ValidateCouponResponse) => {
    setDiscount(values);
  };

  const calculateDiscount = (price: number): number => {
    if (!discount) {
      return 0;
    }

    if (discount.discount_type === CouponDiscountType.FIXED) {
      return discount.discount_value / 100;
    }

    return price * (discount.discount_value / 100);
  };

  return (
    <div className="col-12 d-flex w-100 justify-content-center ">
      <div className="rounded-075 bg-white p-4 text-center border-shadown">
        <h3 className="h4 mb-3">Confirme sua compra</h3>
        <p className="text-black-300 fs-14">
          Por favor, revise os detalhes do pagamento
        </p>
        <hr />
        <div className="d-flex col-12 flex-wrap">
          <div className="d-flex col-12 justify-content-between">
            <span className="text-black-500 font-weight-400 fs-15">Plano</span>
            <span className="font-weight-500 fs-17">{data.plan.title}</span>
          </div>
          <br />
          <br />
          <div className="d-flex col-12 justify-content-between">
            <span className="text-black-500 font-weight-400 fs-15">
              Descrição
            </span>
            <span className="font-weight-400 fs-16">{data.plan.subtitle}</span>
          </div>
          <br />
          <br />

          {discount && (
            <>
              <div className="d-flex col-12 justify-content-between">
                <span className="text-black-500 font-weight-400 fs-15">
                  Preço
                </span>
                <span className="font-weight-500 fs-18 ">
                  {labelMaskFormatToReal(String(data.plan.price))}
                </span>
              </div>
              <br />
              <br />
            </>
          )}

          {discount && (
            <>
              <div className="d-flex col-12 justify-content-between">
                <span className="text-black-500 font-weight-400 fs-15">
                  Desconto
                </span>
                <span className="font-weight-400 fs-18 ">
                  -{" "}
                  {labelMaskFormatToReal(
                    String(calculateDiscount(data.plan.price))
                  )}
                </span>
              </div>
              <br />
              <br />
            </>
          )}
          <div className="d-flex col-12 justify-content-between">
            <span className="text-black-500 font-weight-400 fs-15">
              Preço Final
            </span>
            <span className="font-weight-600 fs-18 text-primary">
              {labelMaskFormatToReal(
                String(data.plan.price - calculateDiscount(data.plan.price))
              )}
            </span>
          </div>
          <br />
          <br />
          <div className="d-flex col-12 justify-content-between">
            <span className="text-black-500 font-weight-400 fs-15">
              Recorrência
            </span>
            <span className="font-weight-400 fs-16">
              {SubscriptionPlanFrequencyLabels(
                data.plan.frequency as SubscriptionPlanFrequencyEnum
              )}{" "}
              (
              {labelMaskFormatToReal(
                String(
                  (data.plan.price - calculateDiscount(data.plan.price)) /
                    data.plan.installments
                )
              )}{" "}
              x {data.plan.installments})
            </span>
          </div>
        </div>

        <br />
        <br />
        <div className="d-flex col-12 justify-content-end">
          <div className="col-12 col-md-4">
            <CouponValidate
              onSuccess={onValidatedCoupon}
              onSetCoupon={setCoupon}
            />
          </div>
        </div>
        <hr />

        <div className="d-flex col-12 flex-wrap">
          <div className="d-flex col-12 justify-content-between">
            <span className="text-black-500 font-weight-400 fs-15">Nome</span>
            <span className="font-weight-400 fs-17">{data.name}</span>
          </div>
          <br />
          <br />
          <div className="d-flex col-12 justify-content-between">
            <span className="text-black-500 font-weight-400 fs-15">
              Documento
            </span>
            <span className="font-weight-400 fs-17">
              {data.document_number}
            </span>
          </div>
          <br />
          <br />
        </div>

        <div className="col-12 d-flex justify-content-end  flex-wrap">
          <Button
            onClick={() => onConfirm(coupon)}
            className="col-12"
            color="primary"
          >
            Finalizar
          </Button>

          {onBack && (
            <Button className="col-12 mt-2" onClick={onBack}>
              Voltar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
