import { Input, InputGroup, InputGroupText } from "reactstrap";
import { FieldProps, getIn } from "formik";

import "components/form/custom-form.style.css";

interface CustomFormInputProps extends FieldProps {
  label: string;
  leftIconClass?: string;
}

export const CustomFormInput: React.FC<CustomFormInputProps> = ({
  field,
  form,
  label,
  leftIconClass,
  ...props
}) => {
  const { name } = field;
  const errorMessage = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);

  return (
    <>
      <InputGroup>
        {leftIconClass && (
          <InputGroupText
            className={`text-secondary bg-white ${
              !!errorMessage && isTouched ? "is-invalid" : ""
            } }`}
          >
            <i className={leftIconClass}></i>
          </InputGroupText>
        )}
        <Input
          {...field}
          {...props}
          className={` ${leftIconClass ? "border-left-0" : "form-control"}`}
          invalid={!!errorMessage && isTouched}
        />
      </InputGroup>
      {isTouched && !!errorMessage && (
        <div className="custom-invalid-feedback">{errorMessage}</div>
      )}
    </>
  );
};
