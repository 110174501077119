import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import {
  SubscriptionPlan,
  SubscriptionPlanService,
} from "services/subscription-plan-service";
import { errorHandler } from "helpers/http-helper";

export const actionListAllSubscriptionPlans = action(
  async (): Promise<SubscriptionPlan[]> => {
    SetIsLoadingState(true);

    try {
      return await SubscriptionPlanService.listAll();
    } catch (error) {
      errorHandler(error, "Falha ao buscar planos de assinatura");
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
