import { httpClient } from "config/http-client";
import { errorHandler } from "helpers/http-helper";
import { CouponDiscountType } from "domain/coupons/enums/coupon-discount-type.enum";

export interface ValidateCouponResponse {
  discount_type: CouponDiscountType;
  discount_value: number;
}

export class CouponService {
  static async validateCoupon(code: string): Promise<ValidateCouponResponse> {
    try {
      const result = await httpClient.request({
        url: `/coupons/validate/${code}`,
        method: "get",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao validar cupom");
      throw new Error("Falha ao validar cupom");
    }
  }
}
