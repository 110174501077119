import { Button, Row, Col, FormGroup, Label } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React from "react";

import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { validateCPF } from 'validators/validate-document';

import "./forgot-password.style.css";

interface ForgotPasswordProps {
  onSendCode: (login: string) => void;
}
interface ForgotPasswordFormValues {
  login: string;
}
const validationSchema = Yup.object().shape({
  login: Yup.string().test(
    "custom-validation",
    "CPF inválido",
    function (value) {
      return validateCPF(value ?? "");
    }
  ),
});

const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
  onSendCode,
}: ForgotPasswordProps) => {
  const initialValues: ForgotPasswordFormValues = {
    login: "",
  };

  const handleSubmit = async (values: ForgotPasswordFormValues) => {
    onSendCode(values.login.replace(/\D/g, ""));
  };

  return (
    <>
      <Row className="d-flex col-12 col-xl-7 d-flex align-items-center row flex-column">
        <img
          className="mx-auto mb-5 col-12"
          src="../../logo-white.png"
          alt="Logo G3MED"
          style={{ width: "150px" }}
        />

        <Col
          sm="12"
          md="8"
          className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
        >
          <h3 className="text-center text-primary">Esqueceu a senha?</h3>
          <p className="px-0">
            Informe o CPF cadastrado que enviaremos e-mail de recuperação de
            senha
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className="px-0">
                <FormGroup className="col-12 px-0">
                  <Label htmlFor="login">CPF</Label>
                  <Field
                    name="login"
                    id="login"
                    placeholder="Digite seu CPF"
                    type="text"
                    mask="000.000.000-00"
                    leftIconClass="fas fa-user text-primary"
                    component={CustomMaskInput}
                  />
                </FormGroup>

                <Button
                  className="my-3 form-control font-weight-600"
                  color="primary"
                  type="submit"
                >
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
          <Row className="text-center  mt-4 px-1">
            <Col sm="12" className="d-flex justify-content-end">
              <a href="/auth/login" className="text-primary fs-16">
                <i className="fas fa-user-doctor"></i> Lembrou a senha ? Faça
                login
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPasswordForm;
