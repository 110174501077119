import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getTableType } from "helpers/screen";
import { errorMessage, successMessage } from "helpers/toast";
import {
  ListScheduleTypeItemTable,
  ListScheduleTypeTable,
} from "./schedule-types-table";
import { FormScheduleType, ScheduleTypeProps } from "./form/form-schedule-type";
import { ScheduleTypeStatusEnum } from "domain/schedule-types/entities/enums/schedule-type-status.enum";
import { actionCreateScheduleType } from "actions/schedule-types/action-create-schedule-type";
import { actionUpdateScheduleType } from "actions/schedule-types/action-update-schedule-type";
import { ListAllScheduleTypesResponse } from "services/schedule-type-service";
import { actionListAllScheduleType } from "actions/schedule-types/action-listall-schedule-types";
import { BadgeComponent } from "components/badge/badge-component";

export const ScheduleTypeContainer: React.FC = () => {
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());
  const [rawScheduleTypes, setRawScheduleTypes] = useState<
    ListAllScheduleTypesResponse[]
  >([]);
  const [scheduleTypes, setScheduleType] = useState<
    ListScheduleTypeItemTable[]
  >([]);
  const [selectedHealthPlan, setSelectedHealthPlan] =
    useState<ScheduleTypeProps | null>(null);

  const fetchData = async () => {
    try {
      const response = await actionListAllScheduleType({});
      setRawScheduleTypes(response);

      const result = response.map((item) => ({
        id: item.id,
        name: nameItemName(item.name, String(item.id)),
        status: statusItemName(item.status as ScheduleTypeStatusEnum, String(item.id)),
      }));
      setScheduleType(result);
    } catch (error) {}
  };

  const onSelectData = (id: string) => {
    const scheduleType = rawScheduleTypes.find(
      (item) => String(item.id) === String(id)
    );
    if (scheduleType) {
      setSelectedHealthPlan(scheduleType as ScheduleTypeProps);
    } else {
      console.log("Tipo de agendamento não encontrado", id);
      errorMessage("Tipo de agendamento não encontrado");
    }
  };

  const nameItemName = (name: string, id: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const statusItemName = (
    healthPlanStatus: ScheduleTypeStatusEnum,
    id: string
  ) => {
    return (
      <div className="w-100">
        {healthPlanStatus == ScheduleTypeStatusEnum.ACTIVE ? (
          <>
            <BadgeComponent
              data={{ name: "Ativo", bgColor: "badge-bg-success" }}
            />
          </>
        ) : (
          <>
            <BadgeComponent
              data={{ name: "Inativo", bgColor: "badge-bg-danger" }}
            />
          </>
        )}
      </div>
    );
  };

  const onSubmit = async (values: ScheduleTypeProps) => {
    try {
      if (values.id) {
        await actionUpdateScheduleType(values.id, values);
        successMessage("Tipo de agendamento atualizado com sucesso");
      } else {
        await actionCreateScheduleType(values);
        successMessage("Tipo de agendamento criado com sucesso");
      }
      setSelectedHealthPlan(null);
      await fetchData();
    } catch {}
  };

  const onCreate = () => {
    setSelectedHealthPlan({
      name: "",
      status: ScheduleTypeStatusEnum.ACTIVE,
    });
  };
  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <div className="main-wrapper">
      <Row className="d-flex justify-content-end mt-2 mb-3">
        <div className="d-flex col-12 col-xl-3 my-2 justify-content-end">
          <Button color="primary" onClick={onCreate}>
            + Criar Tipo de Agendamento
          </Button>
        </div>
      </Row>

      <div>
        <ListScheduleTypeTable
          data={scheduleTypes}
          onSelectData={onSelectData}
          tableType={tableType}
          setTableType={setTableType}
        />
      </div>

      <Modal
        isOpen={selectedHealthPlan !== null}
        toggle={() => setSelectedHealthPlan(null)}
        size="md"
      >
        <ModalHeader toggle={() => setSelectedHealthPlan(null)}>
          Tipo de agendamento
        </ModalHeader>
        <ModalBody>
          <FormScheduleType
            data={selectedHealthPlan}
            onSubmit={onSubmit}
            onCancel={() => setSelectedHealthPlan(null)}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};
