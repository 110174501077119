import React from "react";
import { Formik, Form, Field } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { CustomPasswordInput } from "components/form/input/custom-password-input";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { OwnerTypeEnum } from "domain/tenants/entities/enums/owner-type.enum";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { RegisterFormValues } from "views/auth/register/register";
import { isPhoneValid } from "helpers/phone-validator";
import { isPasswordValid } from "helpers/password-validator";
import { validateCPF } from "validators/validate-document";
import { CheckboxTermForm } from "./components/checkbox-term-form";
import { ButtonSubmitForm } from "./components/button-submit-form";

interface RegisterPJFormValues {
  tenant_name: string;
  user_name: string;
  document_number: string;
  phone: string;
  email: string;
  password: string;
  confirm_password: string;
  owner_type: OwnerTypeEnum;
  term: boolean;
}

const validationSchema = Yup.object().shape({
  tenant_name: Yup.string().required("Nome da clínica é obrigatório"),
  user_name: Yup.string().required("Nome do gestor é obrigatório"),
  document_number: Yup.string().test(
    "custom-validation",
    "CPF inválido",
    function (value) {
      return validateCPF(value ?? "");
    }
  ),
  phone: Yup.string().test("phone", "Telefone inválido", (value) => {
    return isPhoneValid(value ?? "");
  }),
  email: Yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  password: Yup.string().test(
    "custom-validation",
    "Senha fraca",
    function (value) {
      return isPasswordValid(value ?? "");
    }
  ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "As senhas precisam ser iguais")
    .min(8, "Senha deve ter no mínimo 8 caracteres")
    .required("Confirmação de senha é obrigatória"),
  term: Yup.boolean().oneOf([true], "Termos de uso são obrigatórios"),
});

interface RegisterPJProps {
  onSubmit: (values: RegisterFormValues) => void;
}

const RegisterPJ: React.FC<RegisterPJProps> = ({
  onSubmit,
}: RegisterPJProps) => {
  const initialValues: RegisterPJFormValues = {
    tenant_name: "",
    user_name: "",
    document_number: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
    owner_type: OwnerTypeEnum.CLINIC,
    term: true,
  };

  const handleSubmit = (
    values: RegisterPJFormValues,
    { setSubmitting }: any
  ) => {
    onSubmit({
      ...values,
      phone: `${values.phone.replace(/\D/g, "")}`,
    });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Row className="d-flex col-12 mx-0">
            <FormGroup className="col-12 col-lg-6 ">
              <Label htmlFor="tenant_id">Nome da Clínica</Label>
              <Field
                name="tenant_name"
                id="tenant_name"
                label="Nome da Clínica"
                placeholder="Nome da Clínica"
                type="text"
                leftIconClass="fas fa-building text-primary"
                component={CustomFormInput}
              />
            </FormGroup>
            <FormGroup className="col-12 col-lg-6 ">
              <Label htmlFor="user_name">Nome do Gestor</Label>
              <Field
                name="user_name"
                id="user_name"
                label="E-mail"
                placeholder="Nome do Gestor"
                type="user_name"
                leftIconClass="fas fa-user text-primary"
                component={CustomFormInput}
              />
            </FormGroup>
          </Row>
          <Row className="d-flex col-12 mx-0">
            <FormGroup className="col-12 col-lg-6 ">
              <Label htmlFor="document_number">CPF</Label>
              <Field
                name="document_number"
                id="document_number"
                label="E-mail"
                placeholder="XXX.XXX.XXX-XX"
                type="document_number"
                leftIconClass="fas fa-id-card text-primary"
                component={CustomFormInput}
              />
            </FormGroup>
            <FormGroup className="col-12 col-lg-6 ">
              <Label htmlFor="email">E-mail</Label>
              <Field
                name="email"
                id="email"
                label="E-mail"
                placeholder="Digite seu e-mail"
                type="email"
                leftIconClass="fas fa-envelope text-primary"
                component={CustomFormInput}
              />
            </FormGroup>
          </Row>
          <Row className="d-flex col-12 mx-0">
            <FormGroup className="col-12 col-lg-6  ">
              <Label htmlFor="email">Telefone</Label>
              <Field
                name="phone"
                id="phone"
                label="Telefone"
                placeholder="(00) 00000-0000"
                type="text"
                mask="(00) 00000-0000"
                leftIconClass="fas fa-phone text-primary"
                component={CustomMaskInput}
              />
            </FormGroup>

            <FormGroup className="col-12 col-lg-6 ">
              <Label htmlFor="password">Senha</Label>
              <Field
                name="password"
                id="password"
                label="Senha"
                placeholder="Digite senha"
                leftIconClass="fas fa-lock text-primary"
                component={CustomPasswordInput}
              />
            </FormGroup>
          </Row>
          <Row className="d-flex col-12 mx-0 d-flex flex-wrap">
            <div className="d-flex col-12 col-lg-6 fs-14 flex-wrap align-items-center">
              <ul>
                <li className="fw-bold">Requisitos da senha:</li>
                <li>No mínimo 8 caracteres</li>
                <li>Pelo menos 1 caractere maiúsculo</li>
                <li>Pelo menos 1 caractere minúsculo</li>
                <li>Pelo menos 1 número</li>
              </ul>
            </div>

            <div className="d-flex col-12 col-lg-6 px-0 flex-wrap">
              <FormGroup className="col-12 ">
                <Label htmlFor="confirm_password">Confirmar Senha</Label>
                <Field
                  name="confirm_password"
                  id="confirm_password"
                  label="Confirmar Senha"
                  placeholder="Confirmar senha"
                  leftIconClass="fas fa-lock text-primary"
                  component={CustomPasswordInput}
                />
              </FormGroup>
            </div>
          </Row>

          <Row className="d-flex text-end">
            <FormGroup className="col-12">
              <CheckboxTermForm />
            </FormGroup>
          </Row>
          <Row className="d-flex col-12 mx-0 mt-2">
            <ButtonSubmitForm />
          </Row>

          <Row className="text-center  mt-4 px-1">
            <Col sm="12" className="d-flex justify-content-end">
              <a href="/auth/login" className="text-primary fs-17">
                <i className="fas fa-user-doctor"></i> Já possui conta ? Faça
                login
              </a>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterPJ;
