import { useEffect, useState } from "react";
import { CurrentPlanCard, Plan } from "./components/current-plan-card";
import {
  Payment,
  PaymentHistoryTable,
} from "./components/payment-history-table";
import { EmptyPaymentHistory } from "./components/empty-payment-history";
import { NoSubscriptionCard } from "./components/no-subscription-card";
import { actionGetSubscription } from "actions/subscription-plan/action-get-subscription";
import { actionGetSubscriptionInvoices } from "actions/subscription-plan/action-get-subscription-invoices";
import { labelMaskFormatToReal } from "helpers/format-money";
import { SubscriptionStatusEnum } from "domain/subscription-plans/enums/subscription-status.enum";
import { SubscriptionInvoiceStatusEnum } from "domain/subscription-plans/enums/subscription-invoice-status.enum";
import "./subscription-management.style.css";
import { SubscriptionPlanFrequencyEnum } from "domain/subscription-plans/enums/subscription-plan-frequency.enum";
import { PaginationMeta } from "components/table/custom-table";
import { getTableType } from "helpers/screen";

const PAGE_SIZE = 20;

export const mockPlan: Plan = {
  title: "Professional Plan",
  subtitle: "Professional Plan",
  price: "$49.99/month",
  billingCycle: SubscriptionPlanFrequencyEnum.Month,
  endsAt: "May 15, 2024",
  status: SubscriptionStatusEnum.ACTIVE,
};

export const mockPayments: Payment[] = [
  {
    id: 1,
    name: "Professional Plan",
    date: "April 15, 2024",
    amount: "49.99",
    status: SubscriptionInvoiceStatusEnum.PAID,
  },
  {
    id: 2,
    name: "Anual Plan",
    date: "March 15, 2024",
    amount: "49.99",
    status: SubscriptionInvoiceStatusEnum.PAID,
  },
  {
    id: 3,
    name: "Monthly Plan",
    date: "February 15, 2024",
    amount: "49.99",
    status: SubscriptionInvoiceStatusEnum.PAID,
  },
];

export const SubscriptionManagementContainer = () => {
  const [currentPlan, setCurrentPlan] = useState<Plan | null>();
  const [paymentHistory, setPaymentHistory] = useState<Payment[]>(mockPayments);

  const [meta, setMeta] = useState<PaginationMeta | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());

  const fetchSubscriptionInvoices = async (page = 1) => {
    const response = await actionGetSubscriptionInvoices({
      limit: PAGE_SIZE,
      page,
    });

    setPaymentHistory(
      response.items.map((invoice) => ({
        id: invoice.id,
        date: invoice.start_at,
        amount: String(invoice.amount),
        status: invoice.status,
        name: invoice.name,
      }))
    );

    setMeta(response.meta);
  };

  const fetchSubscription = async () => {
    const response = await actionGetSubscription();
    setCurrentPlan({
      title: response.plan.name,
      subtitle: response.plan.subtitle,
      price: labelMaskFormatToReal(String(response.plan.price)),
      billingCycle: response.plan_frequency,
      endsAt: response.ends_at,
      status: response.status,
    });
  };

  const onSelectPaymentHistory = (_id: string) => {};

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
    await fetchSubscriptionInvoices(page);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([fetchSubscription(), fetchSubscriptionInvoices()]);
    })();
  }, []);

  return (
    <div className="main-wrapper">
      <div className="grid gap-8 md:grid-cols-2">
        {currentPlan ? (
          <>
            <div className="col-12 col-md-12">
              <CurrentPlanCard plan={currentPlan} />
            </div>
            <div className="col-12 col-md-12">
              <PaymentHistoryTable
                payments={paymentHistory}
                meta={meta}
                onSelectItem={onSelectPaymentHistory}
                onChangePage={onChangePage}
                tableType={tableType}
                setTableType={setTableType}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-12 col-md-6">
              <NoSubscriptionCard />
            </div>
            <div className="row" />
            <div className="col-12 col-md-6">
              <EmptyPaymentHistory />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
