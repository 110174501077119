import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ManagerCardProps,
  SubscriptionPlanService,
} from "services/subscription-plan-service";

export const actionManagerCardSubscription = action(
  async (input: ManagerCardProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await SubscriptionPlanService.managerCard(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
