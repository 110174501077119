import { makeAutoObservable } from "mobx";
import { listAllCardsResponse } from "~/services/subscription-plan-service";

class SubscriptionStore {
  isShowFeatures: boolean = false;
  cards: listAllCardsResponse[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsShowFeatures(isShowFeatures: boolean): void {
    this.isShowFeatures = isShowFeatures;
  }

  getIsShowFeatures(): boolean {
    return this.isShowFeatures;
  }

  setCards(cards: listAllCardsResponse[]): void {
    this.cards = cards;
  }

  getCards(): listAllCardsResponse[] {
    return this.cards;
  }
}

const subscriptionStore = new SubscriptionStore();

export default subscriptionStore;
