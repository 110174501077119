import { ReactElement, useEffect, useState } from "react";
import { actionListAllCards } from "actions/subscription-plan/action-listall-cards";
import subscriptionStore from "state/subscription/subscription-store";
import {
  ADD_NEW_CARD,
  FormPaymentSection,
} from "../form-sections/form-payment-section";
import { FormAddressSection } from "../form-sections/form-address-section";
import { FormChangeCardReviewSection } from "../form-sections/form-change-card-review-section";
import { useNavigate } from "react-router-dom";
import { ManagerCardProps } from "services/subscription-plan-service";
import { getCardBrand } from "helpers/cards";
import { actionManagerCardSubscription } from "actions/subscription-plan/action-manager-card-subscription";
import { successMessage } from "helpers/toast";
import { errorHandler } from "helpers/http-helper";

enum SubscriptionNavigation {
  PAYMENT_DATA = "PAYMENT_DATA",
  ADDRESS_DATA = "ADDRESS_DATA",
  CHANGE_CARD_REVIEW = "CHANGE_CARD_REVIEW",
}

export const ManagerCreditCardContainer: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<SubscriptionNavigation>(
    SubscriptionNavigation.PAYMENT_DATA
  );

  const [formValues, setFormValues] = useState({
    card_id: "",
    card: {
      holder_name: "",
      number: "",
      expiration: "",
      cvv: "",
      holder_document: "",
      brand: "",
    },
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      zip_code: "",
    },
  });

  const onBackTab = () => {
    if (activeTab === SubscriptionNavigation.CHANGE_CARD_REVIEW) {
      if (formValues.card_id === ADD_NEW_CARD) {
        setActiveTab(SubscriptionNavigation.ADDRESS_DATA);
      } else {
        setActiveTab(SubscriptionNavigation.PAYMENT_DATA);
      }
    } else if (activeTab === SubscriptionNavigation.ADDRESS_DATA) {
      setActiveTab(SubscriptionNavigation.PAYMENT_DATA);
    } else {
      navigate("/painel/assinatura/gerenciamento");
    }
  };

  const onSubmit = (values: any) => {
    setFormValues({ ...formValues, ...values });

    if (
      activeTab === SubscriptionNavigation.PAYMENT_DATA &&
      values?.card_id === ADD_NEW_CARD
    ) {
      setActiveTab(SubscriptionNavigation.ADDRESS_DATA);
    } else {
      setActiveTab(SubscriptionNavigation.CHANGE_CARD_REVIEW);
    }
  };

  const onFinish = async () => {
    try {
      const payload: ManagerCardProps = {
        ...formValues,
        address: {
          ...formValues.address,
        },
        card: {
          ...formValues.card,
          expiration_month: formValues.card.expiration.split("/")[0],
          expiration_year: formValues.card.expiration.split("/")[1],
          brand: getCardBrand(formValues.card.number),
        },
        card_id:
          formValues.card_id === ADD_NEW_CARD
            ? undefined
            : Number(formValues.card_id),
      };

      await actionManagerCardSubscription(payload);
      navigate("/painel/assinatura/gerenciamento");
      successMessage("Assinatura realizada com sucesso");
    } catch {}
  };

  useEffect(() => {
    (async () => {
      const cards = await actionListAllCards();
      subscriptionStore.setCards(cards);
    })();
  }, []);

  const mapTabToComponent = new Map<SubscriptionNavigation, ReactElement>();
  mapTabToComponent.set(
    SubscriptionNavigation.PAYMENT_DATA,
    <FormPaymentSection
      onBack={onBackTab}
      onSubmit={onSubmit}
      data={{ ...formValues.card }}
    />
  );

  mapTabToComponent.set(
    SubscriptionNavigation.ADDRESS_DATA,
    <FormAddressSection
      onBack={onBackTab}
      onSubmit={onSubmit}
      data={{ ...formValues.address }}
    />
  );

  mapTabToComponent.set(
    SubscriptionNavigation.CHANGE_CARD_REVIEW,
    <FormChangeCardReviewSection onBack={onBackTab} onConfirm={onFinish} />
  );

  return <div className="main-wrapper">{mapTabToComponent.get(activeTab)}</div>;
};
