import { httpClient } from "config/http-client";
import { errorHandler } from "helpers/http-helper";
import { SubscriptionPlanFrequencyEnum } from "domain/subscription-plans/enums/subscription-plan-frequency.enum";
import { SubscriptionPlanStatusEnum } from "domain/subscription-plans/enums/subscription-plan-status.enum";
import { SubscriptionStatusEnum } from "domain/subscription-plans/enums/subscription-status.enum";
import { PaymentTypeEnum } from "domain/schedule/enums/payment-type.enum";
import { SubscriptionInvoiceStatusEnum } from "domain/subscription-plans/enums/subscription-invoice-status.enum";
import { PaginationMeta } from "components/table/custom-table";
import { successMessage } from "helpers/toast";

export interface SubscriptionPlan {
  id: number;
  external_id: string;
  name: string;
  subtitle: string | null;
  description: string[];
  price: number;
  frequency: SubscriptionPlanFrequencyEnum;
  installments: number;
  is_default: boolean;
  status: SubscriptionPlanStatusEnum;
  trial_days: number;
  sms_limit: number;
  wpp_notification_limit: number;
  created_at: string;
  updated_at: string;
}

export interface SubscriptionPlanSubscribeProps {
  subscription_plan_id: number;
  payment_method: PaymentTypeEnum;
  card: {
    cvv: string;
    holder_document: string;
    number: string;
    holder_name: string;
    brand: string;
    expiration_month: string;
    expiration_year: string;
  };
  card_id?: number;
  phone: string;
  name: string;
  document_number: string;
  address: {
    state: string;
    city: string;
    zip_code: string;
    complement: string;
    neighborhood: string;
    street: string;
    number: string;
  };
  coupon?: string
}

export interface GetSubscriptionResponse {
  id: number;
  plan_frequency: SubscriptionPlanFrequencyEnum;
  status: SubscriptionStatusEnum;
  created_at: string;
  ends_at: string;
  plan: {
    name: string;
    subtitle: string;
    price: number;
  };
}

export interface GetSubscriptionInvoiceProps {
  limit: number;
  page: number;
}

export interface GetSubscriptionInvoiceItems {
  id: number;
  name: string;
  payment_type: PaymentTypeEnum;
  created_at: string;
  amount: number;
  start_at: string;
  status: SubscriptionInvoiceStatusEnum;
}

export interface GetSubscriptionInvoiceResponse {
  items: GetSubscriptionInvoiceItems[];
  meta: PaginationMeta;
}

export interface listAllCardsResponse {
  id: number;
  brand: string;
  last_digits: string;
  expiration_month: string;
  expiration_year: string;
  is_used: boolean;
}

export interface ManagerCardProps {
  card: {
    cvv: string;
    holder_document: string;
    number: string;
    holder_name: string;
    brand: string;
    expiration_month: string;
    expiration_year: string;
  };
  card_id?: number;
  address: {
    state: string;
    city: string;
    zip_code: string;
    complement: string;
    neighborhood: string;
    street: string;
    number: string;
  };
}

export class SubscriptionPlanService {
  static async listAll(): Promise<SubscriptionPlan[]> {
    try {
      const result = await httpClient.request({
        url: "/subscription-plans/plans",
        method: "get",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao buscar planos de assinatura");
      throw new Error("Falha ao buscar planos de assinatura");
    }
  }

  static async subscribeSubscription(
    payload: SubscriptionPlanSubscribeProps
  ): Promise<void> {
    try {
      await httpClient.request({
        url: "/subscription-plans/subscribe",
        method: "post",
        data: payload,
      });
    } catch (err) {
      errorHandler(err, "Falha ao realizar assinatura");
      throw new Error("Falha ao realizar assinatura");
    }
  }

  static async getSubscription(): Promise<GetSubscriptionResponse> {
    try {
      const response = await httpClient.request({
        url: "/subscription-plans/subscription",
        method: "get",
      });
      return response.data;
    } catch (err) {
      errorHandler(err, "Falha ao realizar assinatura");
      throw new Error("Falha ao realizar assinatura");
    }
  }

  static async getSubscriptionInvoices(
    input: GetSubscriptionInvoiceProps
  ): Promise<GetSubscriptionInvoiceResponse> {
    try {
      const response = await httpClient.request({
        url: "/subscription-plans/subscription/invoices",
        method: "get",
        params: input,
      });
      return response.data;
    } catch (err) {
      errorHandler(err, "Falha ao realizar assinatura");
      throw new Error("Falha ao realizar assinatura");
    }
  }

  static async listAllCards(): Promise<listAllCardsResponse[]> {
    try {
      const result = await httpClient.request({
        url: "/subscription-plans/subscription/cards",
        method: "get",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao cancelar assinatura");
      throw new Error("Falha ao buscar cartões");
    }
  }

  static async cancelSubscription(): Promise<void> {
    try {
      await httpClient.request({
        url: "/subscription-plans/subscription",
        method: "delete",
      });
      successMessage("Assinatura cancelada com sucesso!");
    } catch (err) {
      errorHandler(err, "Falha ao cancelar assinatura");
      throw new Error("Falha ao cancelar assinatura");
    }
  }

  static async managerCard(payload: ManagerCardProps): Promise<void> {
    try {
      await httpClient.request({
        url: "/subscription-plans/subscription/card",
        method: "patch",
        data: payload,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar cartão");
      throw new Error("Falha ao atualizar cartão");
    }
  }
}
