import Select, { createFilter } from "react-select";
import { useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";
import { cities } from "helpers/cities-list";
import { customStyles } from "./helpers/react-select-styles";
import { getIn } from "formik";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectUfFormProps {
  keyName: string;
  isReadOnly?: boolean;
}

export const SelectUfForm: React.FC<SelectUfFormProps> = ({
  keyName,
  isReadOnly,
}: SelectUfFormProps) => {
  const { setFieldValue, values, errors } = useFormikContext();

  const onChange = (selectedOption: any) => {
    setFieldValue(keyName, selectedOption);
  };

  const fieldValue = getIn(values, keyName); // Safely retrieve nested value
  const fieldError = getIn(errors, keyName); // Safely retrieve nested error

  return (
    <div>
      <FormGroup className="col-12 px-0">
        <Label htmlFor={keyName}>Estado</Label>
        <Select
          id={keyName}
          isClearable
          isSearchable
          onChange={onChange}
          value={fieldValue}
          placeholder="UF"
          options={cities}
          filterOption={createFilter(filterConfig as any)}
          isDisabled={isReadOnly}
          styles={customStyles}
        />
      </FormGroup>
      {fieldError && (
        <div className="custom-invalid-feedback">Estado inválido</div>
      )}
    </div>
  );
};
