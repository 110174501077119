export const EmptyPaymentHistory = () => {
  return (
    <div className="subscription-card">
      <div className="d-flex flex-wrap justify-content-center align-items-center mb-2">
        <i
          className="fa fa-credit-card text-primary fs-19"
          aria-hidden="true"
        ></i>{" "}
        &nbsp; &nbsp;
        <div className="font-weight-600 fs-19">Histórico de pagamento</div>
      </div>

      <div className="text-center">
        <p className="text-black-300 fs-16 font-weight-400">
          Você não possui nenhum histórico de pagamento. Suas transações serão
          exibidas aqui quando houver um plano assinado.
        </p>
      </div>
    </div>
  );
};
